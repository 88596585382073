import { useAddToCartMutation, useCreateNewCartMutation, useGetCartByIdQuery, useRemoveFromCartMutation, useUpdateCartMutation } from "apis/supplementStore.api";
import useSelf from "./useSelf"
import { useEffect } from "react";
import { customAlert } from "utils/helperFunctions";

const useCart = () => {

  const { shopify_cart_id, email } = useSelf();
  const { data: cart, isFetching, isLoading } = useGetCartByIdQuery(shopify_cart_id, {
    skip: !shopify_cart_id
  });
  const [add, { isLoading: isAddToCartLoading }] = useAddToCartMutation();
  const [remove, { isLoading: isRemoveCartItemLoading }] = useRemoveFromCartMutation();
  const [update, { isLoading: isUpdateCartQuantityLoading }] = useUpdateCartMutation();
  const [createCart] = useCreateNewCartMutation();

  const getProductLineId = (productId) => {
    const lineItem = cart?.lines?.edges?.find(item => item?.node?.merchandise?.id === productId)
    return lineItem?.node?.id ?? '';
  }
  // const getProductQuantity = (productId) => {
  //   const lineItem = cart?.lines?.edges?.find(item => item?.node?.merchandise?.id === productId)
  //   return lineItem?.node?.quantity || 0
  // }

  const addtoCart = ({ productId, quantity }) => () => {
    // find the current quantity of the product in the cart then add 1 to it if it exists otherwise 1
    // const quantity = getProductQuantity(productId) + 1
    add({ cart_id: shopify_cart_id, product_id: productId, quantity: quantity ?? 1 }).unwrap()
      .then(() => {
        customAlert({ message: 'Product added to cart successfully', type: 'success' })
      }).catch(e => {
        console.log(e)
      })
  }

  const removeFromCart = ({ lineId, callback }) => () => {
    remove({ cart_id: shopify_cart_id, line_id: lineId }).unwrap()
      .then(() => {
        if (callback) callback?.()
      })
      .catch(e => {
        if (callback) callback?.()
        console.log(e)
      })
  }

  const updateCartQuantity = ({ lineId, quantity }) => {
    update({ cart_id: shopify_cart_id, line_id: lineId, quantity })
  }


  useEffect(() => {
    if (cart?.lines?.edges?.length > 0) {
      const zeroQuantityItems = cart.lines.edges.filter(item => item.node.quantity === 0);
      zeroQuantityItems.forEach(item => {
        removeFromCart({ lineId: item?.node?.id })();
      })
    }
  }, [cart])

  useEffect(() => {
    if (!isLoading || !isFetching) {
      if (!cart?.id) {
        createCart({ email }).unwrap()
      }
    }
  }, [isLoading, isFetching, cart])


  return {
    addtoCart,
    removeFromCart,
    updateCartQuantity,
    getProductLineId,
    cart: cart,
    isAddToCartLoading,
    isUpdateCartQuantityLoading,
    isRemoveCartItemLoading,
  }
}

export default useCart