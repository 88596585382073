import React, { Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import theme from "theme";
import { ThemeProvider } from "@emotion/react";
import ProtectedRoute from "components/Layout/ProtectedRoute";
import UnProtectedRoutes from "components/Layout/UnProtectedRoutes";
import Loader from 'components/Loader';
// import useListenNotifications from 'shared/hooks/useListenNotifications';
// import { onMessageListener } from 'init-fcm';
// import { useDispatch, useSelector } from 'react-redux';

const _404 = lazy(() => import('containers/_404'));
const ForgetPassword = lazy(() => import('containers/Auth/ForgetPassword'));
const ResetPassword = lazy(() => import('containers/Auth/ResetPassword'));
const Login = lazy(() => import('containers/Auth/Login'));
const Register = lazy(() => import('containers/Auth/Register'));
const Landing = lazy(() => import('containers/Landing'));
const AccountSettings = lazy(() => import('containers/AccountSettings'));
const PatientHealthRecords = lazy(() => import('containers/PatientHealthRecords'));
const CreateAppointment = lazy(() => import('containers/CreateAppointment'));
const HealthArticles = lazy(() => import('containers/HealthArticles'));
// const DetailConsultationView = lazy(() => import('containers/DetailConsultationView'));
const OrderTracking = lazy(() => import('containers/OrderTracking'));
const OrderDetail = lazy(() => import('containers/OrderDetail'));
const SupplementStore = lazy(() => import('containers/SupplementStore'));
const SupplementDetail = lazy(() => import('containers/SupplementDetail'));
const Appointments = lazy(() => import('containers/Appointments'));
const RescheduleAppointment = lazy(() => import('containers/RescheduleAppointment'));
const Chat = lazy(() => import('containers/Chat'));
const PatientAddressSettings = lazy(() => import('containers/PatientAddressSettings'));
const SupplementCheckout = lazy(() => import('components/SupplementCheckout'));
const StripeRedirect = lazy(() => import('containers/StripeRedirect'));



// const registerServiceWorker = async () => {
//   if ("serviceWorker" in navigator) {
//     try {
//       const registration = await navigator.serviceWorker.register("../public/firebase-messaging-sw.js", {
//         scope: "/",
//       });
//       if (registration.installing) {
//         console.log("Service worker installing");
//       } else if (registration.waiting) {
//         console.log("Service worker installed");
//       } else if (registration.active) {
//         console.log("Service worker active");
//       }
//     } catch (error) {
//       console.error(`Registration failed with ${error}`);
//     }
//   }
// };


function App() {
  // useListenNotifications();
  // registerServiceWorker();
  // const dispatch = useDispatch();
  // const unreadNotifications = useSelector(state => state?.user?.unreadNotifications) ?? [];
  // useEffect(() => {
  //   // Listen for incoming messages
  //   onMessageListener()
  //     .then((payload) => {

  //       console.log('Message received: ', payload);
  //       // Handle incoming messages here
  //     })
  //     .catch((err) => console.log('Message listener error: ', err));
  // }, []);


  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='*' element={<Suspense fallback={<Loader pageLoader />}><_404 /></Suspense>} />
        <Route path='/' element={<Suspense fallback={<Loader pageLoader />}><Landing /></Suspense>} />
        <Route element={<Suspense fallback={<Loader pageLoader />}><UnProtectedRoutes /></Suspense>}>
          <Route path='/forget-password' element={<Suspense fallback={<Loader pageLoader />}><ForgetPassword /></Suspense>} />
          <Route path='/reset-password' element={<Suspense fallback={<Loader pageLoader />}><ResetPassword /></Suspense>} />
          <Route index path='/login' element={<Suspense fallback={<Loader pageLoader />}><Login /></Suspense>} />
          <Route path='/register' element={<Suspense fallback={<Loader pageLoader />}><Register /></Suspense>} />
        </Route>
        <Route element={<Suspense fallback={<Loader />}><ProtectedRoute /></Suspense>}>
          {/* <Route path='/dashboard' element={<Suspense fallback={<Loader />}><Dashboard /></Suspense>} /> */}
          <Route path='/appointments' element={<Suspense fallback={<Loader pageLoader />}><Appointments /></Suspense>} />
          <Route path='/supplement-store' element={<Suspense fallback={<Loader pageLoader />}><SupplementStore /></Suspense>} />
          <Route path='/settings' element={<Suspense fallback={<Loader pageLoader />}><AccountSettings /></Suspense>} />
          <Route path='/health-records' element={<Suspense fallback={<Loader pageLoader />}><PatientHealthRecords /></Suspense>} />
          <Route path='/new-appointment' element={<Suspense fallback={<Loader pageLoader />}><CreateAppointment /></Suspense>} />
          <Route path='/health-articles' element={<Suspense fallback={<Loader pageLoader />}><HealthArticles /></Suspense>} />
          <Route path='/order-tracking' element={<Suspense fallback={<Loader pageLoader />}><OrderTracking /></Suspense>} />
          {/* <Route path='/consultation/:id' element={<Suspense fallback={<Loader />}><DetailConsultationView /></Suspense>} /> */}
          <Route path='/order' element={<Suspense fallback={<Loader pageLoader />}><OrderDetail /></Suspense>} />
          <Route path='/supplement/:id' element={<Suspense fallback={<Loader pageLoader />}><SupplementDetail /></Suspense>} />
          <Route path='/reschedule-appointment/:id' element={<Suspense fallback={<Loader pageLoader />}> <RescheduleAppointment /> </Suspense>} />
          <Route path='/chat' element={<Suspense fallback={<Loader pageLoader />}> <Chat /> </Suspense>} />
          <Route path='/personal-info' element={<Suspense fallback={<Loader pageLoader />}> <PatientAddressSettings /> </Suspense>} />
          <Route path='/supplement-checkout' element={<Suspense fallback={<Loader pageLoader />}> <SupplementCheckout /> </Suspense>} />
          <Route path='/order-confirmed' element={<Suspense fallback={<Loader pageLoader />}> <StripeRedirect /> </Suspense>} />

        </Route>
      </Routes>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 3000,
          success: {
            duration: 3000,
          },
          error: {}
        }}
      />
    </ThemeProvider>
  );
}
export default App;
